import React, { useEffect, useState } from 'react';
import { fetchData, jobRes } from '../../auth/api'
import { Button, CircularProgress, Tooltip, Dialog, Typography, Grid, Box, CardContent, DialogTitle, DialogActions, DialogContent } from '@mui/material';
import { AutoGraph } from '@mui/icons-material'
import { getProdDevUrl } from '../../tools/commonFunction';
import { styled } from '@mui/system';


const StyledDialog = styled(Dialog)`
    .MuiPaper-root {
        border-radius: 25px !important;
    }
`;

// Styled component for each job item
const JobContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    // boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    // borderRadius: '10px',
    // padding: theme.spacing(3),
    display: 'flex',
    width: "100%",
    flexDirection: 'column',
    height: '100%',
    borderBottom: "2px solid #ddd",
    marginBottom:"20px",
    transition: 'transform 0.2s ease-in-out',
    '&:hover': {
        transform: 'scale(1.02)',
    },
}));

// Custom styling for each Typography block
const Title = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    color: theme.palette.primary.main,
}));

const Subtitle = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(1),
    fontSize: '0.95rem',
    color: theme.palette.text.secondary,
}));

// Custom style for ul elements
// const StyledList = styled('ul')(({ theme }) => ({
//     paddingLeft: theme.spacing(2),
//     marginBottom: theme.spacing(3),
//     '& li': {
//         marginBottom: theme.spacing(2),
//     },
// }));

const StyledList = styled(Box)(({ theme }) => ({
    '& ul, & ol': {
        paddingLeft: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    '& li': {
        paddingLeft: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

function QualificationSection() {
    const [loading, setLoading] = useState(true);
    const [educations, setEducation] = useState([]);
    const [experiences, setExperience] = useState([]);
    const [certifications, setCertifications] = useState([]);
    const [showTooltip, setShowTooltip] = useState(false);
    const [extraCurr, setExtraCurr] = useState([]);
    const [jobResData, setJobRes] = useState([]);
    const [open, setOpen] = useState(false);
    const [id, setId] = useState("");
    const [title, setTitle] = useState("");
    const [skills, setSkills] = useState([]);
    const tables = ['education', 'experience', 'certifications', 'skills', 'extra_curr'];

    useEffect(() => {
        fetchData(tables)
            .then(responseData => {
                const getdata = responseData.saklayen;
                const experience = getdata.experience;
                const extras = getdata.extra_curr;
                const certification = getdata.certifications;
                const skill = getdata.skills;
                const education = getdata.education;
                setExtraCurr(extras);
                setEducation(education);
                setSkills(skill);
                setCertifications(certification);
                setExperience(experience);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
        // eslint-disable-next-line
    }, []);

    const stripHTML = (html) => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    }

    const handleClose = () => {
        setOpen(false)
        setTitle("");
    };

    const handleClick = (isOpen, value, id) => {
        setLoading(true);
        jobRes(id)
            .then(res => {
                setLoading(false);
                setOpen(isOpen);
                setTitle(value);
                setJobRes(res.saklayen.job_res);
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
            });
    };


    const renderJobDetails = () => {
        if (jobResData.length === 0) {
            return <Typography variant="h6" align="center" style={{ marginTop: '50px' }}>Sorry! No Job Responsibilities found.</Typography>;
        }

        return (
            <Grid container spacing={3} style={{ padding: '10px' }}>
                {jobResData.map((job) => (
                    <Grid item xs={12} sm={12} key={job.id} style={{ display: 'flex' }}>
                        <JobContainer>
                            <Title variant="h6" gutterBottom>
                                Name of Client: {job.client_name}
                            </Title>
                            <Subtitle variant="body1">
                                <strong>Engagement Type:</strong> {job.audit_type}
                            </Subtitle>
                            <Subtitle variant="body1">
                                <strong>Job Role:</strong> {job.task_role}
                            </Subtitle>
                            <Subtitle variant="body1" gutterBottom>
                                <strong>Industry Type:</strong> {job.client_type}
                            </Subtitle>
                            <Title sx={{margin:'15px 0px'}} variant="body1" component="div" gutterBottom>
                                <strong>Job Responsibilities:</strong>
                            </Title>

                            {/* Display the responsibilities with ul and li */}
                            <StyledList dangerouslySetInnerHTML={{ __html: job.res_details }} />

                        </JobContainer>
                    </Grid>
                ))}
            </Grid>
        );
    };


    const renderDialog = () => {
        return (
            <Dialog open={open} onClose={handleClose} fullScreen fullWidth maxWidth="lg">
                <DialogTitle className='dialog'>Job Responsibilities of <b>{title}</b> </DialogTitle>
                {loading ? (
                    <div className='spinnerBar'>
                        <CircularProgress
                            style={{
                                color: '#5e2fd6'
                            }}
                        />
                    </div>
                ) : (
                    <>
                        <DialogContent className='dialog'>
                            {renderJobDetails()}
                        </DialogContent>
                        <DialogActions>
                            <button className='button btn-send' onClick={handleClose}>
                                Close
                            </button>
                        </DialogActions>
                    </>

                )}

            </Dialog>
        );
    };

    function ExperienceTimeline({ experienceData }) {
        return (
            <div id="experienceTimeline">
                {experienceData.sort((b, a) => a.rank - b.rank).map((item) => (
                    <div className="timeline-item clearfix" key={item.company + item.position}>
                        <div className="left-part">
                            <h5 className="item-period">{item.period}</h5>
                            <span className="item-company">{item.position}</span>
                        </div>
                        <div className="divider"></div>
                        <div className="right-part">
                            <h4 className="item-title">
                                <span
                                    onMouseEnter={() => setShowTooltip(true)}
                                    onMouseLeave={() => setShowTooltip(false)}
                                >
                                    {item.company}
                                </span>

                                <Tooltip
                                    title="Job Responsibility"
                                    placement="top-start"
                                    arrow
                                    open={showTooltip}
                                >
                                    <Button
                                        onClick={() => handleClick(true, item.company, item.id)}
                                        sx={{
                                            boxShadow: "none",
                                            marginLeft: '4px',
                                            '&:hover': {
                                                border: "none",
                                                background: "none",
                                                color: "#5e2fd6",
                                            },
                                            '&:active': {
                                                background: "none",
                                            },
                                            '&:focus': {
                                                background: "none",
                                                border: "none",
                                                color: "#5e2fd6",
                                            }
                                        }}
                                        size='small'
                                    >
                                        <AutoGraph />
                                    </Button>
                                </Tooltip>
                            </h4>
                            <p className='expDesc'>{stripHTML(item.job_res)}</p>
                        </div>
                    </div>
                ))}
                {open && renderDialog()}
            </div>
        );
    }
    function ExtraTimeline({ extraData }) {
        return (
            <div id="experienceTimeline">
                {extraData.sort((b, a) => a.rank - b.rank).map((item) => (
                    <div className="timeline-item clearfix" key={item.company + item.position}>
                        <div className="left-part">
                            <h5 className="item-period">{item.period}</h5>
                            <span className="item-company">{item.position}</span>
                        </div>
                        <div className="divider"></div>
                        <div className="right-part">
                            <h4 className="item-title">{item.company}</h4>
                            <p className='expDesc'>{stripHTML(item.job_res)}</p>
                        </div>
                    </div>
                ))}
            </div>
        );
    }


    function Skills({ skillsData, category }) {
        const getSkillLevelPercentage = (level) => {
            switch (level) {
                case "Beginner":
                    return 60;
                case "Intermediate":
                    return 80;
                case "Advanced":
                    return 100;
                default:
                    return 0;
            }
        };

        if (category === "finance_accounting") {
            return (
                <ul id="skillsContainerFin">
                    {skillsData.sort((b, a) => a.rank - b.rank).filter(item => item.category === "finance_accounting").map(skill => (
                        <li key={skill.skill}>{skill.skill}</li>
                    ))}
                </ul>
            );
        }

        if (category === "coding") {
            return (
                <div id="skillsContainerCode">
                    {skillsData.sort((b, a) => a.rank - b.rank).filter(item => item.category === "coding").map(skill => {
                        const percentage = getSkillLevelPercentage(skill.level);
                        return (
                            <div key={skill.skill} className="clearfix">
                                <div className="skill clearfix">
                                    <h4>{skill.skill}</h4>
                                    <div className="skill-value">{skill.level}</div>
                                </div>
                                <div className={`skill-container skill-${percentage}`}>
                                    <div className="skill-percentage"></div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            );
        }

        if (category === "technology") {
            return (
                <ul id="skillsContainerTech">
                    {skillsData.sort((b, a) => a.rank - b.rank).filter(item => item.category === "technology").map(skill => (
                        <li key={skill.skill}>{skill.skill}</li>
                    ))}
                </ul>
            );
        }

    }


    function Certifications({ certificationsData }) {
        return (
            <div className='row' id="certificationsTimeline">
                {certificationsData.sort((b, a) => a.rank - b.rank).map((item) => (
                    <div
                        className="col-xs-12 col-sm-6"
                        key={item.title + item.issuer}
                        onClick={() => window.open(item.link)}
                    >
                        <div className="certificate-item clearfix">
                            <div className="certi-logo">
                                <img src={`${getProdDevUrl()}/assets/img/${item.icon}`} alt="logo" />
                            </div>
                            <div className="certi-content">
                                <div className="certi-title">
                                    <h4>{item.title}</h4>
                                </div>
                                <div className="certi-id">{item.course_category}</div>
                                <div className="certi-date">
                                    {new Date(item.date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}
                                </div>
                                <div className="certi-company">{item.issuer}</div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    }


    function EducationTimeline({ educationData }) {
        return (
            <div id="educationTimeline">
                {educationData.sort((b, a) => a.rank - b.rank).map((item) => (
                    <div className="timeline-item clearfix" key={item.title}>
                        <div className="left-part">
                            <h5 className="item-period">{item.period}</h5>
                        </div>
                        <div className="divider"></div>
                        <div className="right-part">
                            <h4 className="item-title">{item.title}</h4>
                            {item.department && <p>{item.department}</p>}
                            <p style={{
                                margin: '.7rem 0rem'
                            }}>{item.institution}</p>
                            <p>{item.grade}</p>
                        </div>
                    </div>
                ))}
            </div>
        );
    }

    return (
        <div className="section-content main_body_wrapper">
            {loading ? (
                <div className='spinnerBar'>
                    <CircularProgress
                        style={{
                            color: '#5e2fd6'
                        }}
                    />
                </div>
            ) : (
                <>
                    <div className="page-title">
                        <h2>Qualifications</h2>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-7">
                            <div className="block-title">
                                <h3>Education</h3>
                            </div>
                            <div className="timeline timeline-second-style clearfix" id="educationTimeline">
                                <EducationTimeline educationData={educations} />
                            </div>
                            <div className="white-space-50"></div>
                            <div className="block-title">
                                <h3>Professional Experience</h3>
                            </div>
                            <div className="timeline timeline-second-style clearfix" id="experienceTimeline">
                                <ExperienceTimeline experienceData={experiences} />
                            </div>
                            <div className="white-space-50"></div>
                            <div className="block-title">
                                <h3>Volunteer Leadership & Involvement</h3>
                            </div>
                            <div className="timeline timeline-second-style clearfix" id="experienceTimeline">
                                <ExtraTimeline extraData={extraCurr} />
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-5 professional_class">
                            <div className="white-space-custom"></div>
                            <div className="block-title">
                                <h3><span>Finance & Accounting</span> Knowledges</h3>
                            </div>
                            <ul className="knowledges" id="skillsContainerFin">
                                <Skills skillsData={skills} category='finance_accounting' />

                            </ul>
                            <div className="white-space-40"></div>
                            <div className="block-title">
                                <h3><span>Coding</span> Skills</h3>
                            </div>
                            <div className="skills-info skills-second-style" id="skillsContainerCode">
                                <Skills skillsData={skills} category='coding' />
                            </div>
                            <div className="white-space-10"></div>
                            <div className="block-title">
                                <h3><span>Technology</span> Knowledges</h3>
                            </div>
                            <ul className="knowledges" id="skillsContainerTech">
                                <Skills skillsData={skills} category='technology' />

                            </ul>
                        </div>
                    </div>
                    <div className="white-space-50"></div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-12">
                            <div className="block-title">
                                <h3>Certifications</h3>
                            </div>
                        </div>
                    </div>
                    <Certifications certificationsData={certifications} />
                </>
            )}
        </div>
    );
}

export default QualificationSection;
